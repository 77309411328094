import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

export const RadioItem = (props: {
  select: any;
  children: any;
  value: number;
  selected: number;
}) => {
  return (
    <RangeItem
      role="radio"
      aria-checked="false"
      tabIndex={0}
      data-value={props.value}
      onClick={props.select}
      className={props.selected === props.value && 'selected'}
    >
      {props.children}
    </RangeItem>
  );
};

export const RadioSelector = (props: {
  selected: number;
  setSelected: any;
}) => {
  const select = (e: any) => {
    props.setSelected(parseInt(e.target.getAttribute('data-value'), 10));
  };

  return (
    <>
      <RangeSelector role="radiogroup">
        <RadioItem selected={props.selected} value={1} select={select}>
          +1
        </RadioItem>
        <RadioItem selected={props.selected} value={2} select={select}>
          +2
        </RadioItem>
        <RadioItem selected={props.selected} value={3} select={select}>
          +3
        </RadioItem>
        <RadioItem selected={props.selected} value={4} select={select}>
          +4
        </RadioItem>
        <RadioItem selected={props.selected} value={5} select={select}>
          +5
        </RadioItem>
      </RangeSelector>
    </>
  );
};

const RangeSelector = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const RangeItem = styled.li<any>`
  background-color: #4b4b4b;
  width: calc(20% - 4px);
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  margin: 0 4px 0 0;
  padding: 0;
  cursor: pointer;

  &.selected {
    background-color: #e8c893;
    color: #000;
  }
`;
