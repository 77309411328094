import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { TypeButton } from './TypeButton';
import { Select } from '../../ui/form/Select';
import { useQuery } from '@apollo/client';
import qs from 'qs';
import { client } from '../../../__graphql__/clientCluster';
import { AVAILABLE_ESTATE_TYPES } from '../../../../client/__graphql__/cluster/queries/search/estate_aggregations';
import { SearchEstateQuery } from '../../../__types__/generated/cluster';
import { ENV_CONFIG } from '../../../__config__/env';

const StyledPropertyTypeSelectorWrapper = styled.div`
  width: 100%;

  .select-wrapper {
    margin-bottom: 10px;
    height: max-content;
  }
`;

export const PropertyTypeSelector = (props: {
  selectedTypes: string[];
  location: any;
  setSelectedTypes: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { loading, data } = useQuery<SearchEstateQuery>(
    AVAILABLE_ESTATE_TYPES,
    {
      client: client,
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          brandId: ENV_CONFIG?.BRAND_ID
        },
        filter: {
          aggregations: true
        }
      }
    }
  );
  useEffect(() => {
    if (!data) {
      return;
    }
    let queryData: any = qs.parse(props.location.search.replace('?', ''));

    const types = data?.search.estate?.aggregations?.estateTypes ?? [];

    if (queryData.ob) {
      let typer = [];
      if (typeof queryData.ob === 'string') {
        typer.push(types[queryData.ob].key);
      } else {
        typer = queryData.ob.map((typ: any) => {
          return types[typ].key;
        });
      }
      props.setSelectedTypes([...props.selectedTypes, ...typer]);
    }
  }, [loading, data]);

  // egen eiendomstypekomp
  const addSelected = (e: any) => {
    if (props.selectedTypes.indexOf(e.target.value) === -1) {
      if (e.target.value === 'Eiendomstype') return;
      props.setSelectedTypes([...props.selectedTypes, e.target.value]);
    }
  };
  const removeSelected = (value: any) => {
    const index = props.selectedTypes.indexOf(value);

    if (index > -1) {
      props.setSelectedTypes([
        ...props.selectedTypes.slice(0, index),
        ...props.selectedTypes.slice(index + 1)
      ]);
    }
  };

  if (loading) return <div>...</div>;

  return (
    <>
      <StyledPropertyTypeSelectorWrapper>
        <Select name="eiendomstype" onChange={addSelected}>
          <option>Eiendomstype</option>
          {data?.search.estate?.aggregations?.estateTypes?.map((item) => (
            <option key={item.key} value={item.key}>
              {item.key}
            </option>
          ))}
        </Select>
      </StyledPropertyTypeSelectorWrapper>
      <div style={{ marginBottom: '2em' }}>
        {props.selectedTypes.map((item, i) => (
          <TypeButton
            close={removeSelected}
            key={`${item}_${i}`}
            value={item}
          />
        ))}
      </div>
    </>
  );
};
