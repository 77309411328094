import { gql } from '@apollo/client';

export const SEARCH_AGGREGATIONS_ESTATE = gql`
  query SearchEstateAggregations(
    $input: SearchInput!
    $filter: SearchFilterEstate
  ) {
    search(input: $input) {
      estate(filter: $filter) {
        total
        aggregations {
          assignmentTypes {
            count
            key
          }
          counties {
            count
            key
          }
          municipalities {
            count
            key
          }
          estateTypes {
            count
            key
          }
          bedrooms {
            count
            key
          }
          sold {
            count
            key
          }
        }
      }
    }
  }
`;

export const AVAILABLE_ESTATE_TYPES = gql`
  query AvailableEstateTypes(
    $input: SearchInput!
    $filter: SearchFilterEstate
  ) {
    search(input: $input) {
      estate(filter: $filter) {
        aggregations {
          estateTypes {
            key
          }
        }
      }
    }
  }
`;
