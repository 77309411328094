import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useMap } from '../../../utils/hooks/useMap';
import { AddressSearch } from './AddressSearch';
import { SiteContext } from '../../../utils/context/SiteContext';

export const GoogleSearch = React.memo((props: any) => {
  const context: any = useContext(SiteContext);
  useMap();

  let center_listener: any = null;
  useEffect(() => {
    if (window && window.google) {
      const settings = {
        disableDefaultUI: true,
        zoomControl: true,
        zoom: 8
      };

      const center = {
        lat: 59.911491,
        lng: 10.757933
      };

      if (!window.gmap) {
        window.gmap = new window.google.maps.Map(
          document.getElementById('map'),
          {
            ...settings,
            center
          }
        );
      }

      if (!window.circle) {
        window.circle = new window.google.maps.Circle({
          strokeColor: '#000000',
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: '#000000',
          fillOpacity: 0.1,
          map: window.gmap,
          center: center,
          radius: props.distance
        });
      }

      center_listener = window.gmap.addListener('center_changed', () => {
        window.circle.setCenter(window.gmap.getCenter());
      });

      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          window.gmap.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (e) => {
          console.log('klarte ikke å hente brukerposisjon');
        }
      );
    }

    return () => {
      if (window.gmap) {
        window.google.maps.event.removeListener(center_listener);
      }
    };
  }, [context.contextData.google]);

  useEffect(() => {
    if (window.circle) {
      window.circle.setRadius(props.distance);
      window.gmap.fitBounds(window.circle.getBounds());
    }
  }, [props.distance]);

  return (
    <>
      <AddressSearch google={context.contextData.google} />
      <div
        style={{
          width: '100%',
          height: '300px',
          margin: '0 0 1em',
          background: '#333'
        }}
        id="map"
      />
    </>
  );
});
