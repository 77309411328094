import { useEffect, useContext } from 'react';
import { SiteContext } from '../context/SiteContext';

export const useMap = () => {
  const context: any = useContext(SiteContext);

  useEffect(() => {
    window.gmap = null;
    window.circle = null;
    if (window.google) {
      return;
    }

    const script = document.createElement('script');
    script.src =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAW_JNiQTjseS07riD_14if3OTGnBqlqjQ&libraries=places&callback=initMap';
    script.defer = true;
    script.async = true;
    window.initMap = () => {
      context.setContextData({ ...context.contextData, google: window.google });
    };
    document.head.appendChild(script);
  }, []);
};
